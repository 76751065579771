let scrollPos = window.scrollY;
const header = document.querySelector('header'),
    headerHeight = document.querySelector('.header__logo').offsetHeight,
    navMenu = document.querySelector('.header__menu'),
    navMenuButton = document.querySelector('.header__menu-btn'),
    navMenuElements = document.querySelectorAll(
        '.header__menu__items a, .footer__menu__items a'
    ),
    addClassOnScroll = () => {
        header.classList.add('scrolled');
    },
    removeClassOnTop = () => {
        header.classList.remove('scrolled');
    },
    showMenu = () => {
        navMenu.classList.toggle('active');
        navMenuButton.classList.toggle('active');
    };

navMenuButton.addEventListener('click', showMenu);

document.addEventListener('DOMContentLoaded', () => {
    scrollPos = window.scrollY;

    if (
        scrollPos >= headerHeight ||
        (scrollPos <= headerHeight && navMenu.classList.contains('scrolled'))
    ) {
        addClassOnScroll();
    } else {
        removeClassOnTop();
    }
});

window.addEventListener('scroll', () => {
    scrollPos = window.scrollY;

    if (
        scrollPos >= headerHeight ||
        (scrollPos <= headerHeight && navMenu.classList.contains('scrolled'))
    ) {
        addClassOnScroll();
    } else {
        removeClassOnTop();
    }
});

window.onresize = function () {
    checkMobileNavbar();
};

navMenuElements.forEach(menu => {
    menu.addEventListener('click', e => {
        e.preventDefault();
        let mobile = checkMobileNavbar();
        let targetId = menu.getAttribute('href');
        let targetElement = document.querySelector(targetId);

        if (mobile.matches) {
            navMenu.classList.remove('active');
            navMenuButton.classList.remove('active');
        }
        if (targetElement) {
            const yOffset = targetElement.offsetTop - headerHeight * 2.5;
            window.scrollTo({ top: yOffset, behavior: 'smooth' });
        }
    });
});

function checkMobileNavbar() {
    let mobile = window.matchMedia('(min-width: 0px) and (max-width: 992px)');
    return mobile;
}
